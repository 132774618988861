module.exports = `<!DOCTYPE html>
<html>

<head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
    <title>add Flipkart device </title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
    <!--<script src="http://maps.google.com/maps/api/js?sensor=false" type="text/javascript"></script> -->
    <script src="http://maps.google.com/maps/api/js?key=AIzaSyAhI7clOT7g0GbbDshU8hTQNnoGYZsrsjA&sensor=false" type="text/javascript"></script>
    <script src="https://momentjs.com/downloads/moment.js"></script>
    <script src="https://unpkg.com/axios/dist/axios.min.js"></script>
    <style type="text/css">
    .row {
        padding: 16px 10px;
    }

    b {
        padding-left: 10px;
    }

    .table td,
    .table th {
        text-align: center;
    }

    .row {
        padding: 2px 9px;
    }

    div#map {
        position: fixed !important;
    }
    </style>
</head>

<body>
    <div class="container">
        <div class="col-md-4">
        </div>
        <div class="col-md-4">
            <h1 style="text-align: center;padding: 8px;margin-bottom: 40px;">Add Flipkart Devices</h1>
            <div class="row" id="device-search">
                <!--<form> -->
                <div class="box">
                    <!-- Material switch -->
<div class="radio" id="facility_type" value="">
  <label><input type="radio" name="facility" value="flipkart" checked>Flipkart</label>
  <label><input type="radio" name="facility" value="transporter">Transporter</label>
</div>

                    <div class="form-group">
                        <label for="deviceId">DeviceId:</label>
                        <input type="text" class="form-control" id="deviceId" name="deviceId" value="" required>
                        <p id="errorMsg" style="color:red;padding-top: 4px;font-size: 13px;padding-bottom: 0px;"></p>
                    </div>
                    <div class="form-group">
                        <label for="deviceId">Add Multiple Devices:</label>
                        <input type="text" class="form-control" id="multipleDevice" name="multipleDevice" value="" required>
                        <p id="errorMsg" style="color:red;padding-top: 4px;font-size: 13px;padding-bottom: 0px;">Seprated with comma</p>
                    </div>
                    <div class="form-group">
                        <label for="sel1">Select list:</label>
                        <select class="form-control selectpicker" id="facility">
                            <option value="">Select Facility</option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-primary" onClick="addDevice()" style="margin-top: 24px;">Submit</button>
                </div>
                <p id="addedDevice" style="color: green"> </p>
                <p id="runningDevice" style="color:red"> </p>
                <h2 id="success" style="color:green"></h2>
            </div>
        </div>
        <div class="col-md-4">
        </div>
    </div>
    <script type="text/javascript">
    facility = [];
    transporterFacilities = [];
    runningDevice = []
    allDevices = []
    var config = {
        headers: { "Authorization": "Basic aW50dWdpbmU6SW50dUBkbSFuMTIz" }
    };
    axios.post('https://flipkart.intutrack.com/api/login', {}, config)
        .then((r) => {
            console.log(r.data)
            config = {
                headers: { "Authorization": "Bearer " + r.data.token }
            };
            axios.get('https://flipkart.intutrack.com/api/trips?running=true', config)
                .then((r) => {
                    allDevices = r.data
                    runningDevice = allDevices.filter((f) => f.running)
                    //  console.log(allDevices)
                    console.log(runningDevice)
                })

            return axios.get('https://flipkart.intutrack.com/api/facilities', config)
        })
        .then((r) => {
            console.log(r.data)
            facility = r.data.all_facilities
            facility = facility.sort(function(a, b) {
                return a.name > b.name
            })
            facility.forEach((f) => {

                $("#facility").append('<option value="' + f.name + '">' + f.name + '</option>');
            })

            console.log("hello moto")
            let type = $("input[name='facility']:checked").val()
            console.log("type", type)
            return axios.get('https://flipkart.intutrack.com/api/facilities?transporter_view=true', config)
        })
        .then((r)=>{
            console.log("transporter facility",r.data)
            transporterFacilities = r.data.all_facilities
        })



    $('#multipleDevice').on('input', function(e) {
        $("#success").html('')
    })

    $("#facility_type").on('change', function(e){
        var facilityType = $("input[name='facility']:checked").val();
        console.log("radio change", facilityType )
        if(facilityType == 'flipkart'){
            $("#facility").html("")
            facility.forEach((f) => {
                $("#facility").append('<option value="' + f.name + '">' + f.name + '</option>');
            })
        }
        else{
            $("#facility").html("")
            transporterFacilities.forEach((f) => {
             $("#facility").append('<option value="' + f.name + '">' + f.name + '</option>');
            })
        }

    })


    function addDevice() {
         $("#addedDevice").html("")
         $("#runningDevice").html("Running Device=")

                 var multipleDevice = []
        var deviceId = $('#deviceId').val();
        var facility = $('#facility').val();
        multipleDevice = $("#multipleDevice").val();

        console.log(multipleDevice)
        if (multipleDevice != "") {
            multipleDevice = multipleDevice.replace(/ /g, '').replace(/-/g, '')
            multipleDevice = multipleDevice.split(',')
        }


        // alert(facility + '' + deviceId)
        if ((deviceId != '' || multipleDevice.length > 0) && facility != '') {

            let data = {
                "location": facility,
                "lane": null,
                "containers": null
            }
            if (multipleDevice.length > 0) {
                let status = false

                multipleDevice.forEach((d) => {
                    console.log(d)
                    let rDevice = runningDevice.findIndex((f) => f.deviceId == d)
                    if (rDevice == -1) {
                        console.log("device is not running" + d)
                        axios.put('https://flipkart.intutrack.com/api/devices/'+d+'?location='+facility,{}, config)
                .then(function(response) {
                    console.log(response);
                    console.log('add to refill')
                    if(response.status == 200){
                    $("#addedDevice").append(", "+d)
                      $("#success").html('device Added Successfully')
                    }
                })
                .catch(function(error) {
                     $("#success").html('')
                    console.log(error);
                });
                    } else {
                        $("#runningDevice").append(', '+d)
                    }
                })

                if (status)
                    alert('Device Added Succesfully')
            } else {
                let rDevice = runningDevice.findIndex((f) => f.deviceId == deviceId)
                if (rDevice == -1) {
                       axios.put('https://flipkart.intutrack.com/api/devices/'+deviceId+'?location='+facility,{}, config)
                             .then(function(response) {
                                 console.log(response);
                                 if(response.status == 200){
                                     $('#deviceId').val("")
                                     $("#success").html(deviceId+'device Added Successfully')
                                 }
                                    
                             })
                             .catch(function(error) {
                                  $("#success").html('')
                                 console.log(error);
                             });
                } else {
                     $("#runningDevice").append(deviceId)
                }
            }




        }
    }
    </script>
</body>

</html>`;