import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import actions from "../store/actions";
import { makeStyles } from "@material-ui/core/styles";
import reactGA from "react-ga";
import { GoogleLogin } from "react-google-login";
// const CLIENT_ID = "210680182603-d9f479rhidgulhsmo1vauaqgc010heno.apps.googleusercontent.com"; // localserver
const CLIENT_ID =  "76971111423-ss1820fv9b2k3lnuje3l2o8qnjj2tl20.apps.googleusercontent.com";
const useStyles = makeStyles({
  login_view: {
    display: "flex",
    justifyContent: "center",
    margin: 300,
  },
  login_btn: {
    width: 200,
    fontSize: 24,
  },
});

function Login(props) {
  const classes = useStyles();

  const logEvent = (user) => {
    reactGA.event({
      category: "Logged In",
      action: `${user}  successfully logged in at ${new Date().toString()}`,
    });
  };

  const log_in = (response) => {
    if (response.tokenId) {
      axios
        .get(
          "https://sxwersbq8j.execute-api.ap-south-1.amazonaws.com/v1/generate_intugine_user_token?email=" +
            response.profileObj.email
        )
        .then(function (res) {
          props.logIn({
            token: res.data.token,
            username: response.profileObj.givenName,
            email: response.profileObj.email,
          });
          logEvent(response.profileObj.givenName);
        })
        .catch(function (error) {
          // props.logout()
          props.history.push("/login");
          console.log(error);
          window.location.replace("https://operations.intutrack.com");
          //   props.logout()
        });
      props.history.push(
        "/home/" +
          (props.fullAcess.includes(response.profileObj.email)
            ? "Tickets V2"
            : (props.showApps[props.email] || props.defaulAccess)[0])
      );
    }
  };
  const handleLoginFailure = (response) => {
    console.log(response);
  };
  return (
    <div className={classes.login_view}>
      <GoogleLogin
        className={classes.login_btn}
        clientId={CLIENT_ID}
        buttonText="Login with Google"
        onSuccess={log_in}
        onFailure={handleLoginFailure}
        cookiePolicy={"single_host_origin"}
        responseType="code,token"
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  loggedIn: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: (payload) => dispatch({ type: actions.LOGIN, payload }),
  // logout: () => dispatch({type:actions.LOGOUT})
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
