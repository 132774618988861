import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import ReactFileReader from 'react-file-reader';
import * as XLSX from 'xlsx';

const vertical = 'top';

const horizontal = 'center';

const useStyles = makeStyles({
  root: {
    width: '500px',
    fontFamily: 'Montserrat',
    margin: 'auto',
    // marginTop: '10%',
  },
  update: {
    fontFamily: 'Montserrat',
    width: '100%',
    marginBottom: '10%',
  },
  emailTextField: {
    width: '100%'
  },
  textArea: {
    // marginBottom: '10%',
    width: '200px',
  },
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  head: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    padding: '5%',
    fontSize: '40px',
  }
});

function SCTUpdate(props) {

  const classes = useStyles();
  
  const [showAlert, setShowAlert] = useState({
    show: false,
    status: '',
    message: '',
  })

  const [userData, setUserData]  = useState({
    username : '',
    changes: {
      email : ['',],
    }
  })

  const [callUpdate, setCallUpdate] = useState(false)

  const emailAddHandler = () => {
    let change = userData.changes;
    change.email.push('');
    setUserData({ ...userData, changes : change })
  }

  const handleDelete = () => {
    let req = {
      url: "https://g9s4fdlv77.execute-api.ap-south-1.amazonaws.com/latest/v1/users?username="+userData.username,
      headers : {
        Authorization: 'Bearer '+ props.token
      },
      method: 'GET',
    }

    let data = {
      username : userData.username,
      changes : {
        email : []
      }
    }

    axios(req)
    .then(response => {
      console.log(response.data.result[0].email)
      response.data.result[0].email.forEach(element => {
        if(!userData.changes.email.includes(element)) {
          console.log(element)
          data.changes.email.push(element)
        }
      });
      console.log(data)
      handleUpdate(data)
    })
    .catch(error => {
      console.log(error)
    })

  }

  const emailDeleteHandler = (index) => {
    let change = userData.changes;
    change.email.splice(index, 1);
    setUserData({ ...userData, changes : change })
  }

  const handleChange = (prop, index, event) => {
    // event.target.name === "username" ? setUserData({ ...userData, [event.target.name]: event.target.value }) : setUserData({ ...userData, [userData.changes.email]: event.target.value })
    if(prop === "email"){
      let change = userData.changes
      change.email[index] = event.target.value
      setUserData({ ...userData, changes : change })
    }
    else {
      setUserData({ ...userData, [prop]: event.target.value })
    }
  }

  

  const handleAlert = () => {
    setShowAlert({ show: false, status:'', message: '' })
  }

  const handleUpdate = (data) => {
    data ? console.log("yes") : console.log("no")
    let req = {
      url: "https://g9s4fdlv77.execute-api.ap-south-1.amazonaws.com/latest/v1/users/info",
      headers : {
        Authorization: 'Bearer '+ props.token
      },
      method: 'PUT',
      data: data 
    }

    axios(req)
    .then(response => {
      console.log(response.data)
      var alert = '';
      if(response.data.status) {
        alert = 'success';
      }
      else {
        alert = 'error';
      }
      setShowAlert({ show: true, status: alert, message: response.data.message })
    })
    .catch(error => {
      console.log(error)
      setShowAlert({ show: true, status: "error", message: "Failed to update!" })
    })
    console.log(userData)
    setUserData({ username: '', changes: { email: ['',] } })
  }
  
  return (
    <div className="App">
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={showAlert.show} autoHideDuration={4000} onClose={handleAlert}>
        <Alert onClose={handleAlert} severity={showAlert.status}>
          {showAlert.message}
        </Alert>
      </Snackbar>
      <Card className={classes.root} variant="outlined">
        <CardContent>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.head}>SCT Email Update</Typography>
              </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center">
              <Grid item xs={6}>
                <strong className={classes.text}>Username :</strong><br/><br/>
                <TextField className={classes.textArea} id="outlined-basic" label="Username" variant="outlined" value={userData.username} name="username" onChange={(event) => handleChange('username', '0', event)} size="small"/>
              </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong className={classes.text}>Email : </strong></TableCell>
                        <TableCell><AddIcon fontSize="large" onClick={emailAddHandler} /></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        userData.changes.email.map((emailField, index) => {
                          return(
                            <TableRow>
                              <TableCell><TextField className={classes.textArea} id="outlined-basic" label="Email" variant="outlined" value={emailField} name="email" onChange={(event) => handleChange("email", index, event)} size="small" /></TableCell>
                              <TableCell><CancelIcon onClick={() => emailDeleteHandler(index)} /></TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
        </CardContent>
        <CardActions>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={6}>
              <Button variant="contained" className={classes.update} color="primary" size="medium" onClick={() => handleUpdate(userData)} >Update</Button>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Button variant="contained" className={classes.update} color="secondary" size="medium" onClick={handleDelete} >DELETE</Button>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={6}>
              {/* 
              <ReactFileReader fileTypes={[".csv",".xls", ".xlsx"]} base64={true} multipleFiles={false} handleFiles={(event) => handleFiles(event)}>
                <button className='btn'>Upload</button>
              </ReactFileReader> */}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
    token: state.token
})


export default connect(mapStateToProps)(SCTUpdate);
