import actions from './actions'

const initialState = {
    token: null,
    loggedIn: false,
    username: null,
    email: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.LOGIN:
            return ({
                ...state,
                username: action.payload.username,
                token: action.payload.token,
                email: action.payload.email,
                loggedIn: true
            })
        case actions.LOGOUT:
            return ({
                ...state,
                username: null,
                email: null,
                token: null,
                loggedIn: false
            })
        default: 
            return state
    }
}

export default reducer;